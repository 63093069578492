
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { getObraRoute, getUserRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { getImgAddress } from '../util/text';
import MyAppPool from './../AppPool';
import { contentFastTypeToType, contentTypeToFastType, isSet, mobToObj } from './../util/typeu';
import "./ReactionGroup.css";
import ReactionItemHome from './ReactionItemHome';
import SkeletonBlock from './SkeletonBlock';

@observer
class ReactionGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {currentPage:1,pageSize:props.pageSize};
    this.showMore = this.showMore.bind(this);
}
  showMore()
  {
    let {currentPage} = this.state;
    if (currentPage<(MyAppPool.session.latestContent.reactions.loadedPages))
        this.setState({currentPage:currentPage+1});
    if (currentPage==(MyAppPool.session.latestContent.reactions.loadedPages))
    {
        MyAppPool.cLatestContent.getMoreRecentReactions();
        this.setState({currentPage:currentPage+1});
    }
  }

  componentDidMount()
  {

  }

  render(){
    let {itens,currentUserId,currentUserRoles,pageSize} = this.props;
    let {currentPage} = this.state;

    let {ready} = this.props;
    if (!ready)
      return <SkeletonBlock  style={{'width':'100%',height:'240px'}}/>

    let maxItens = pageSize*currentPage;
    let filteredItens = itens.slice(0,maxItens);
    let lastLoadedBatchAmount=MyAppPool.session.latestContent.reactions.lastLoadedItensAmount;
    let loadedPages = MyAppPool.session.latestContent.reactions.loadedPages;
    return <div className="comp_reactiongroup">
        <div class="flexDrop" style={{"paddingBottom":"5px"}}>
        {filteredItens.map((item, index) => {
        return <div className='doubleColumnItem'  key={index}><ReactionItemHome
                reactionItem={MyAppPool.session.reactionData[item]}
                currentUserRoles={currentUserRoles}
                currentUserId={currentUserId}/></div>
        })
        }
        </div>
        
        {lastLoadedBatchAmount===0 && (loadedPages===currentPage) || filteredItens.length===0 ?
        <></>:
        <button aria-label={lng('Mais') } onClick={this.showMore} className="uk-button uk-button-primary moreButton">{lng('Mais') }</button>
        
        }
    </div>;
}
}

ReactionGroup.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.shape({
  displaytext: PropTypes.string.isRequired,
  publishdate:PropTypes.string.isRequired,
  votes:PropTypes.number.isRequired,
  authorname:PropTypes.string.isRequired,
  authorimg:PropTypes.string.isRequired,
  author:PropTypes.number.isRequired
  })),
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  pageSize: PropTypes.number,
  ready:PropTypes.number
};

ReactionGroup.defaultProps = {
    itens: [{
    displaytext: '',
    publishdate:'',
    votes:0,
    authorname:'Defaulter',
    authorimg:'',
    author:0
    }],
    currentUserId: 0,
    currentUserRoles: ['guest'],
    pageSize:4,
    ready:0
};


export default ReactionGroup;
