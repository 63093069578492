import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import MyAppPool from '../AppPool';
import { getObraRoute, getTopsRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { isMobile } from '../util/mobile';
import { getImgAddress, getSEOUriPath, getSiteShortName } from '../util/text';
import { isSet, mangaFastResourceType } from '../util/typeu';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import MusicCardGroup from '../view_component/MusicCardGroup';
import ReactionGroup from '../view_component/ReactionGroup';
import ReviewGroup from '../view_component/ReviewGroup';
import SkeletonBlock from '../view_component/SkeletonBlock';
import TopAnimeCollumn from '../view_component/TopAnimeCollumn';
import { isServer } from './../util/windowUtil';
import RelatedWorks from './../view_component/RelatedWorks';
import TrailerBox from './../view_component/TrailerBox';
import { serverRegisterFetch } from '../util/updateManager';
import './home.css';
import TopsAnime from './topAnime';
import LinkV2 from '../view_component/LinkV2';
import HomeBanner from '../view_component/HomeBanner';


function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}

let upcomingPageSize=10;
let airingPageSize=10;
let popularPageSize=10;
let popularCarrouselSize=30;
@observer
class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {finishedLoading:false};
    serverRegisterFetch(Home.getInitialValues);
  }

  
  componentDidMount()
  {
    Home.getInitialValues().then(()=>this.setState({finishedLoading:true}));
  }
  static async getInitialValues()
  {
    
    let p1 = MyAppPool.cLatestContent.getMoreRecentTrailers();
    let p2 = MyAppPool.cTops.getTopAiringAnimePageByType('TV',0,airingPageSize);
    let p3 = MyAppPool.cTops.getTopUpcomingAnimePageByType('',0,upcomingPageSize);
    let p4 = MyAppPool.cTops.getTopPopularAnimePageByPeriodAndType('month','',0,popularPageSize);
    let p5 = MyAppPool.cCurrentSeason.getCurrentSeasonAnime();
    let p6 = MyAppPool.cCurrentSeason.getCurrentSeasonAnimeOther();
    let p7 = MyAppPool.cLatestContent.getMoreRecentReviews();
    let p8 = MyAppPool.cLatestContent.getMoreRecentReactions();
    let p9 = MyAppPool.cLatestContent.getMoreRecentMusic();
    let p10 = MyAppPool.cTops.getTopPopularMangaPageByPeriodAndType('week','Manga',0,popularCarrouselSize);
    let p11 = MyAppPool.cTops.getTopPopularMangaPageByPeriodAndType('week','Novel',0,popularCarrouselSize);
    let p12 = MyAppPool.cTops.getTopPopularMangaPageByPeriodAndType('week','Light Novel',0,popularCarrouselSize);
    

    return await Promise.all([p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12]);
    
    //return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    Home.getInitialValues();
  }

  render(){
    let topFilters = TopsAnime.getFilterKeys();
    let topAiringQuery = '?'+topFilters.criteria+'=Grade&'+topFilters.period+'=airing';
    let topUpcomingQuery = '?'+topFilters.criteria+'=Popularity&'+topFilters.period+'=upcoming';
    let topPopularQuery = '?'+topFilters.criteria+'=Popularity&'+topFilters.period+'=month';
    let topPopularWeekQuery = '?'+topFilters.criteria+'=Popularity&'+topFilters.period+'=week';
    let topPopularNovelWeekQuery = topPopularWeekQuery + "&"+topFilters.type+"=Novel";
    let topPopularLightNovelWeekQuery = topPopularWeekQuery + "&"+topFilters.type+"=Light Novel";
    let trailers = MyAppPool.session.latestContent.trailers.items;
    let {finishedLoading} = this.state;
    let topAiringAnime=[];
    let topAiringAnimePage = MyAppPool.cTops.getTopAiringPage(MyAppPool.session.tops,'anime','TV',0,airingPageSize);
    if (isSet(topAiringAnimePage))
      topAiringAnime=topAiringAnimePage.itens;

    let upcomingAnime=[];
    let upcomingAnimePage = MyAppPool.cTops.getTopUpcomingPage(MyAppPool.session.tops,'anime','all',0,upcomingPageSize); 
    if (isSet(upcomingAnimePage))
      upcomingAnime=upcomingAnimePage.itens;

    let popularAnimeMonth=[];
    let popularAnimeMonthPage = MyAppPool.cTops.getTopPopularPage( MyAppPool.session.tops,'anime','all','month',0,popularPageSize);
    if (isSet(popularAnimeMonthPage))
      popularAnimeMonth=popularAnimeMonthPage.itens;

    let reviews = [];
    if (Array.isArray(MyAppPool.session.latestContent.reviews.items))
      reviews=MyAppPool.session.latestContent.reviews.items;
    let reactionPosts = [];
    if (Array.isArray(MyAppPool.session.latestContent.reactions.items))
      reactionPosts=MyAppPool.session.latestContent.reactions.items;
    let musics = [];
    if (Array.isArray(MyAppPool.session.latestContent.musics.items))
      musics=MyAppPool.session.latestContent.musics.items;

    let popularMangaMonth=[];
    let popularMangaMonthPage = MyAppPool.cTops.getTopPopularPage( MyAppPool.session.tops,'manga','Manga','week',0,popularCarrouselSize);
    if (isSet(popularMangaMonthPage))
      popularMangaMonth=popularMangaMonthPage.itens;
    let showableMangaMonthItens=[];
    for(let i=0; i< popularMangaMonth.length;i++)
    {
        let element = popularMangaMonth[i];
        showableMangaMonthItens[i] = {
            url:  getObraRoute(element.id,getSEOUriPath (element.title),'manga','#top'),
            //directUrl:getObraRoute(element.id,getSEOUriPath(element.title),'anime','#top'),
            imgurl:element.mainimg, 
            title:element.title,
            icon: '',
            iconText: '',
            resourceType:mangaFastResourceType,
            resourceId:element.id
        }
    }

    let popularNovelMonth=[];
    let popularNovelMonthPage = MyAppPool.cTops.getTopPopularPage( MyAppPool.session.tops,'manga','Novel','week',0,popularCarrouselSize);
    if (isSet(popularNovelMonthPage))
      popularNovelMonth=popularNovelMonthPage.itens;
    let showableNovelMonthItens=[];
    for(let i=0; i< popularNovelMonth.length;i++)
    {
        let element = popularNovelMonth[i];
        showableNovelMonthItens[i] = {
            url:  getObraRoute(element.id,getSEOUriPath (element.title),'manga','#top'),
            //directUrl:getObraRoute(element.id,getSEOUriPath(element.title),'anime','#top'),
            imgurl:element.mainimg, 
            title:element.title,
            icon: '',
            iconText: '',
            resourceType:mangaFastResourceType,
            resourceId:element.id
        }
    }

    let popularLightNovelMonth=[];
    let popularLightNovelMonthPage = MyAppPool.cTops.getTopPopularPage( MyAppPool.session.tops,'manga','Light Novel','week',0,popularCarrouselSize);
    if (isSet(popularLightNovelMonthPage))
      popularLightNovelMonth=popularLightNovelMonthPage.itens;
    let showableLightNovelMonthItens=[];
    for(let i=0; i< popularLightNovelMonth.length;i++)
    {
        let element = popularLightNovelMonth[i];
        showableLightNovelMonthItens[i] = {
            url:  getObraRoute(element.id,getSEOUriPath (element.title),'manga','#top'),
            //directUrl:getObraRoute(element.id,getSEOUriPath(element.title),'anime','#top'),
            imgurl:element.mainimg, 
            title:element.title,
            icon: '',
            iconText: '',
            resourceType:mangaFastResourceType,
            resourceId:element.id
        }
    }

    //console.log('Musics:::',mobToObj(musics));

    let groupsPageSize=4;
    let sliderHeight=352;
    let sliderShowItens=5;
    let sliderImgSize='';
    if (isMobile.any)
    {
      groupsPageSize=3;
      sliderHeight=225;
      sliderShowItens=4;
      sliderImgSize='x50';
    }
    

    let meta = {title:'Home | '+getSiteShortName()+'',desc:'',img:'/favicon.ico',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
   
    let desc = lng('Bem vindo ao Nosso Anime Network! A plataforma de anime e mangá Brasileira mais moderna e mais atualizada, um database e comunidade. Faça parte da comunidade, Crie suas listas de animes ,mangás, empresas, personagens, pessoas, listas padrões e customizadas também! Leia resenhas, explore as comunidades, notícias e muito mais!');
    meta =  {
      title:lng('Home')+' | '+getSiteShortName()+'',
      desc:desc,
      keywords:'',
      img:'/favicon.ico',
      imgwidth:'225',
      imgheight:'338',
      imgtype:'jpg'
    };
    /*onReady={this.onYoutubeReady}
                      onPause={this.onPause}
                      onPlay={this.onPlay}
                      onEnd={this.onVideoEnd}
                      onError = {this.onError}
                      autoplay= {shouldPlay}*/
    return (
      <div className="home">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="title" content={meta.title} />
          <meta name="description" content={meta.desc} />
          <meta name="keywords" content={meta.keywords}/>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.desc} />
          <meta name="twitter:title" content={meta.title}/>
          <meta name="twitter:description" content={meta.desc}/>
          <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
          <meta property="og:image" content={getImgAddress(meta.img)} />
          <meta property="og:image:type" content={meta.imgtype}/>
          <meta property="og:image:width" content={meta.imgwidth}/>
          <meta property="og:image:height" content={meta.imgheight}/> 
        </Helmet>
        <div className="contentpage">
        <ContentBannerSetter images={["homecomponent"]} modes = {["component"]} component=
        {<HomeBanner images={{}}/>}   />
        <div className='uk-flex uk-flex-row contentArea'> 
            <div className="leftContent">
                  
            </div>

            <div className="middleContent" >
              <div className='uk-flex uk-flex-row'>
              <div className="middleLeft" >
                <TopAnimeCollumn items={topAiringAnime} topsName={lng('Top Animes em Exibição')} moreUrl={getTopsRoute('anime',topAiringQuery,'#top')} 
                ready={finishedLoading || topAiringAnime.length>0}/>
                <TopAnimeCollumn showType={true} items={upcomingAnime} topsName={lng('Animes mais Esperados')} moreUrl={getTopsRoute('anime',topUpcomingQuery,'#top')} 
                ready={finishedLoading || upcomingAnime.length>0}/>
                <TopAnimeCollumn max={10} showType={true} items={popularAnimeMonth} topsName={lng('Animes mais Populares esse Mês')} moreUrl={getTopsRoute('anime',topPopularQuery,'#top')} 
                ready={finishedLoading || popularAnimeMonth.length>0}/>
              </div>
              <div className="middleRight" >
                <TrailerBox items={trailers} ready={finishedLoading || trailers.length>0}/>
                <div style={{marginTop:"8px"}}>
                <LazyLoadComponent 
                scrollPosition={MyAppPool.scrollPosition }
                visibleByDefault={isServer()}
                placeholder={<SkeletonBlock  style={{'width':'100%',height:'216px'}}/>}
                >
                  <ReactionGroup
                  itens={reactionPosts}
                  currentUserRoles={MyAppPool.currentUser.roles}
                  currentUserId={MyAppPool.currentUser.userid}
                  ready={finishedLoading || reactionPosts.length>0}  
                  pageSize={groupsPageSize}/>
                </LazyLoadComponent>
                </div>

              

                <div style={{marginTop:"8px"}}>
                {lng("Animes da Temporada")} 
                <LazyLoadComponent 
                scrollPosition={MyAppPool.scrollPosition }
                visibleByDefault={isServer()}
                placeholder={<SkeletonBlock  style={{'width':'100%',height:'376px'}}/>}
                >
                  <RelatedWorks 
                  title=''
                  notFoundText={lng('Carregando')+'...'}
                  notFoundButtonText={''}
                  showItemNumber={sliderShowItens}
                  items={MyAppPool.session.currentSeason.anime} 
                  hideIfEmpty={0}
                  imgSize={sliderImgSize}
                  ready={finishedLoading || MyAppPool.session.currentSeason.anime.length>0}  
                  height={sliderHeight}/>
                </LazyLoadComponent>
              </div>

              <div style={{marginTop:"8px"}}>
              {lng("Últimas Trilhas Sonoras")} 
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'376px'}}/>}
              >
                <MusicCardGroup items={musics} 
                ready={finishedLoading || musics.length>0} 
                />
              </LazyLoadComponent>
              </div>

             

              <div style={{marginTop:"15px"}}>
              {lng("Filmes e Especiais da Temporada")} 
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'376px'}}/>}
              >
                <RelatedWorks 
                title=''
                notFoundText={lng('Carregando')+'...'}
                notFoundButtonText={''}
                showItemNumber={sliderShowItens}
                items={MyAppPool.session.currentSeason.otherAnimation} 
                hideIfEmpty={0}
                imgSize={sliderImgSize}
                ready={finishedLoading || MyAppPool.session.currentSeason.otherAnimation.length>0} 
                height={sliderHeight}/>
              </LazyLoadComponent>
              </div>

              <div style={{marginTop:"15px"}}>
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'376px'}}/>}
              >
                <ReviewGroup
                    currentUserId={MyAppPool.currentUser.userid}
                    currentUserRoles={MyAppPool.currentUser.roles}
                    itens={reviews}
                    ready={finishedLoading || reviews.length>0}
                    pageSize={groupsPageSize}
                  />    
              </LazyLoadComponent>
                </div>    

                <div style={{marginTop:"15px"}}>
              <LinkV2 to={getTopsRoute('manga',topPopularWeekQuery,'#top')}>{lng("Mangas Populares Essa Semana")} </LinkV2>
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'376px'}}/>}
              >
                <RelatedWorks 
                title=''
                notFoundText={lng('Carregando')+'...'}
                notFoundButtonText={''}
                showItemNumber={sliderShowItens}
                items={showableMangaMonthItens} 
                hideIfEmpty={0}
                imgSize={sliderImgSize}
                ready={finishedLoading || showableMangaMonthItens.length>0}
                height={sliderHeight}/>
              </LazyLoadComponent>
              </div>

              <div style={{marginTop:"15px"}}>
              <LinkV2 to={getTopsRoute('manga',topPopularNovelWeekQuery,'#top')}>{lng("Romances Populares Essa Semana")}</LinkV2>
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'376px'}}/>}
              >
                <RelatedWorks 
                title=''
                notFoundText={lng('Carregando')+'...'}
                notFoundButtonText={''}
                showItemNumber={sliderShowItens}
                items={showableNovelMonthItens} 
                hideIfEmpty={0}
                imgSize={sliderImgSize}
                ready={finishedLoading || showableNovelMonthItens.length>0}
                height={sliderHeight}/>
              </LazyLoadComponent>
              </div>

              <div style={{marginTop:"15px"}}>
              <LinkV2 to={getTopsRoute('manga',topPopularLightNovelWeekQuery,'#top')}>{lng("Romances Leves Populares Essa Semana")}</LinkV2>
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'376px'}}/>}
              >
                <RelatedWorks 
                title=''
                notFoundText={lng('Carregando')+'...'}
                notFoundButtonText={''}
                showItemNumber={sliderShowItens}
                items={showableLightNovelMonthItens} 
                hideIfEmpty={0}
                imgSize={sliderImgSize}
                ready={finishedLoading || showableLightNovelMonthItens.length>0}
                height={sliderHeight}/>
              </LazyLoadComponent>
              </div>               

              </div>
              </div>
            </div>

            <div className="rightContent">

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
