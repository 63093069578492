import { createMemoryHistory } from 'history';
import createBrowserHistory from 'history/createBrowserHistory';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
//import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';
import { Route, Router, Switch } from 'react-router-dom';
import './App.css';
import MyAppPool from './AppPool';
import './css/animation.css';
import './css/darkTheme.css';
import './css/lightTheme.css';
import './css/main.css';

import { views } from './store/AppRoutes';
import { serviceHosts } from './store/ServiceHosts';
import { getAlternateLocales, getCurrentLanguage, getMainLocale, getTwitterUser, getUrlAlternatesWithLocale, getUserPreferredLanguages, lng, matchUserLanguagesWithOptions, translateText, websiteLanguageNotMatchingUserLanguage } from './ulanguages/language';
import { getImgAddress } from './util/text';
import { getCanonicalUrl, getCurrentDomain, getCurrentPath, isServer } from './util/windowUtil';
import './view/searchBase.css';
import Erro404 from './view_component/Erro404';
import GlobalAlert from './view_component/GlobalAlert';
import GlobalLoginBox from './view_component/GlobalLoginBox';
import GlobalModal from './view_component/GlobalModal';
import GlobalMusicPlayer from './view_component/GlobalMusicPlayer';
import GlobalPageLoadingBar from './view_component/GlobalPageLoadingBar';
import PopMenuReceiver from './view_component/PopMenuReceiver';
import ScrollTracker from './view_component/ScrollTracker';
import AppFooter from './view_navigation/AppFooter';
import TopBar from './view_navigation/TopBar';
import LanguageRedirect from './view_component/LanguageRedirect';

if (!isServer())
{
  //ReactGA.initialize(serviceHosts.gtag);

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://files.nuecloud.cyou/analise/";
    _paq.push(['setTrackerUrl', u+'batatinha.php']);
    _paq.push(['setSiteId', serviceHosts.siteId]);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'analise.js'; s.parentNode.insertBefore(g,s);
  })();
  
 /*
    var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  _mtm.push(["setRequestMethod", "POST"]);
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://files.nuecloud.cyou/analise/js/container_NTM9kAwS.js'; s.parentNode.insertBefore(g,s);
  })();
  */

}

//Start Thread Workers
//startWorker();
var MyRouter;
var history;
if (isServer())
{
  MyRouter = Router;
  console.log("Server Rendering");
}else
{
  MyRouter = Router;
  history =  createBrowserHistory({
    /* pass a configuration object here if needed */
  }) ;
  MyAppPool.cLocation.setReactRouterHistory(history);
  console.log("Client Rendering");
}

@observer class App extends Component {
  constructor(props) {
    super(props);

    if (isServer())
      history=createMemoryHistory({
        initialEntries: [getCurrentPath()]
    });
    MyAppPool.session.theme=MyAppPool.cPreference.getTheme();
    
  }
  componentDidMount()
  {
    if (isServer())
      return;
      
    MyAppPool.cLocation.setAlternativeUrls();
    MyAppPool.cAuth.fetchUserData();
    MyAppPool.cCampaign.getCampaign();

    let userLang = navigator.language || navigator.userLanguage;
    let languagePref = MyAppPool.recoverItemFromLocalStorage("languageRedirect");

    if (websiteLanguageNotMatchingUserLanguage() && languagePref!==true)
      MyAppPool.cModal.set(lng('Language',false,userLang.split('-')[0]),<LanguageRedirect/>,null,'',null);
  }
  render(){
    let meta = {
      title: lng('Meus Animes Brasili'),
      desc:lng('Meus Anime Brasili , tudo sobre animes e mangas em um lugar só! Comunidade localizada de animes e mangá ! Siga as têndencias de animes, conheça a trilha sonora de animes, veja as datas de lançamento de animes , os trailers de animes , crie suas listas de animes, mangás , empresas , musicas , e muito mais!'),
      logoImg:'/imgs/defaults/logofull.png',
      keywords:'informação de animes e mangas , trailers de anime, trilha sonora de anime , autores de anime e manga , novidades de anime e manga , análises de anime e manga '
    };
    let alternateLocales = getAlternateLocales();
    let alternateUrls = getUrlAlternatesWithLocale();
    if (Array.isArray(MyAppPool.session.locationExtra.locales))
      alternateLocales=MyAppPool.session.locationExtra.locales;
    if (Array.isArray(MyAppPool.session.locationExtra.alternateUrls))
      alternateUrls=MyAppPool.session.locationExtra.alternateUrls;
    
    //let theme = MyAppPool.session.theme;
    if (!isServer())
      document.documentElement.className =  MyAppPool.session.theme;
    //meta for article page
    //<meta name=”news_keywords” content=”World Cup, Qatar 2022, soccer, football”>
    //<meta property="og:type" content="article" />
    //<meta property="og:type" content="article">
    //<meta property="article:author" content="reidark">
    //<meta property="article:section" content="Tutoriais">
    //<meta property="article:tag" content="Facebook, tags, og, open graph">
    //<meta property="article:published_time" content="date_time"></meta>
    //more here https://ogp.me/
    //For content written by user, póiting to external links: rel="nofollow"
    //e.g <a href://www.testdomain.com/page.html" rel="nofollow">Visit this website</a>
    return (
      <div id="appRoot" >
         <Helmet htmlAttributes={{ lang : getCurrentLanguage() }}>
         
            <script className='structured-data-list' type="application/ld+json">{JSON.stringify(MyAppPool.session.jsonld)}</script>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.logoImg)}/>
            <meta property="og:image" content={getImgAddress(meta.logoImg)} />
            <meta property="og:image:type" content="image/png"/>
            <meta property="og:image:width" content="441"/>
            <meta property="og:image:height" content="240"/> 

            <meta name="author" content='MaxBar'/>
            <meta name="copyright" content="Brasili" />
            <meta Name = "Web-Author" content='MaxBar'/>

            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></meta>
            <meta
              name="google-site-verification"
              content="blabla"
            />          
           
            
            <meta http-equiv="content-language" content={getMainLocale().replace('_','-')}/>
            <meta name="language" content={getMainLocale().replace('_','-')}/>
            <meta property="og:locale" content={getMainLocale()} />
            {alternateLocales.map((element, index) => <meta key={index} property="og:locale:alternate" content={element} />)}
            {alternateUrls.map((element, index) => <link rel="alternate" href={element.url} hreflang={element.hreflang} key={index} />)}

            <meta property="og:site_name" content={meta.title} />
            <meta property="og:url" content={getCanonicalUrl()} />
            <link rel="canonical" href={getCanonicalUrl()} />

            <meta name="robots" content="index, follow" />

            <meta name="apple-mobile-web-app-capable" content="yes"/>
            <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
            <meta name="mobile-web-app-capable" content="yes"/>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>

            

            <meta name="twitter:creator" content={getTwitterUser()}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content={getTwitterUser()}/>
            
            <meta name="twitter:domain" content={getCurrentDomain()}/>
            
          </Helmet>
        
        <div id="appBody" >
        <MyRouter history = {history}>
          
          <PopMenuReceiver/>
          <ScrollTracker/>
          <GlobalPageLoadingBar/>
          <TopBar/>
          <Switch>
          {
            views.map(function(view, index){
              return <Route  exact={view.exact} key={index} path={view.realPath} 
              render = {() => (<view.component {...view.baseProps}/>) } />;
            })
          }
          <Route path="*" render={() => <Erro404 />} />
        </Switch>
        <GlobalModal/>
        <GlobalAlert/>
        <GlobalLoginBox/>
        <AppFooter/>
        <GlobalMusicPlayer/>
        </MyRouter>
       
        </div>
        
        
        
      </div>
    );
}
}

export default App;

