import { toJS } from "mobx";
import MyAppPool from "../AppPool";
import { routes } from "../store/ApiRoutes";
import { externalRequest } from "../util/net";
import { getCurrentLanguage } from "../ulanguages/language";
import supporters from "../store/supporters.json";


export default class CampaignController
{
    getApoiaseCampaign(){
        externalRequest(routes.getApoiaseCampaign).then(res =>{
            let apiData = res.data.data;
            let {campaign} = MyAppPool.session;
            let naniCampaign = apiData.campaigns[0];
            if (naniCampaign)
            {
                campaign.money = naniCampaign.supports.total.value;
                campaign.users = naniCampaign.supports.total.count;
                campaign.goals = [];
                naniCampaign.goals.map((elem) =>{
                    campaign.goals.push({
                        title: elem.title,
                        desc: elem.desc,
                        value: elem.value
                    });
                })
                campaign.currency = "R$";
                campaign.loaded = true;
                campaign.url = "https://novo.apoia.se/support/leferdie/new";
            }
        });
    }

    getCampaign(){
        let {campaign} = MyAppPool.session;
        let lang = getCurrentLanguage();
        if (lang==="pt")
        {
            campaign.url = "https://novo.apoia.se/support/leferdie/new";
            return this.getApoiaseCampaign();
        }else
        {
            campaign.url = "https://buymeacoffee.com/leferdie";
        }
    }
    getSupporters(){
        MyAppPool.session.campaign.supporters = supporters;
    }
}