import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import "./ContentBanner.css";
import MyAppPool from '../AppPool';
import { observer } from 'mobx-react';
import { isSet } from '../util/typeu';
import { toJS } from 'mobx';

@observer
class ContentBanner extends Component {
  componentDidMount()
  {

  }

  render(){

    let {lastMode,mode,change,image,lastImage}=MyAppPool.session.contentBanner;
    let changeClass="";
    let changeClass2="";
    if (change)
    {
      changeClass="animateChangeIn";
      changeClass2="animateChangeOut";
    }
    
      return (
          <div className={"comp_contentBanner "}>
            {change? lastImage.map((elem,index)=>{
              if (!isSet(elem))
              return <></>;
              if (lastMode[index]==='component')
                return <div className={"uk-background-cover imgTop "+changeClass2} style={{backgroundImage: "url("+elem+ ")"}}>{toJS(MyAppPool.jsObjectStore.lastBannerComponent)}</div>;
              if (lastMode[index]==='bg')
                return <div className={"uk-background-cover imgTop "+changeClass2} style={{backgroundImage: "url("+elem+ ")"}}></div>;
              if (lastMode[index]==='capa')
                return <div className={"uk-background-cover imgTop opacityBorders capa "+changeClass2} style={{backgroundImage: "url("+elem+ ")"}}></div>;
            }):<></>}
            {image.map((elem,index)=>{
              if (!isSet(elem))
                return <></>;
              if (mode[index]==='component')
                return [<div className={"uk-background-cover imgTop2 "+changeClass} style={{backgroundImage: "url("+elem+ ")"}}>
                  {toJS(MyAppPool.jsObjectStore.bannerComponent)}
                </div>,<link rel="preload" href={elem} as="image"/>];
              if (mode[index]==='bg')
                return [<div className={"uk-background-cover imgTop2 "+changeClass} style={{backgroundImage: "url("+elem+ ")"}}></div>,<link rel="preload" href={elem} as="image"/>];
              if (mode[index]==='capa')
              {
                console.log("BANNER CAPA::",elem);
                return [
                
                <div className={"uk-background-cover opacityBorders imgTop2 capa "+changeClass} style={{backgroundImage: "url("+elem+ ")"}}></div>
                
                ,<link rel="preload" href={elem} as="image"/>];
              }
            })}
          </div>
      );
}
}
ContentBanner.propTypes = {
  url: PropTypes.string.isRequired
};
ContentBanner.defaultProps = {
  url:''
};

export default ContentBanner;
