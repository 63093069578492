import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../AppPool';
import { getObraRoute, getSearchRoute, getTopsRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { getRelatedTypeIcon } from '../util/icon';
import { getImgAddress, getSiteShortName } from '../util/text';
import { contentTypeToFastType, isSet, mobToObj } from '../util/typeu';
import { getCurrentPath, isServer, scrollToAnchor } from '../util/windowUtil';
import SearchCard from '../view_component/SearchCard';
import UrlParamHorizontalListButtons from '../view_component/UrlParamHorizontalListButtons';
import UrlParamRemoverTags from '../view_component/UrlParamRemoverTags';
import UrlParamSelect from '../view_component/UrlParamSelect';
import { serverRegisterFetch } from '../util/updateManager';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import SwitcherHeading from '../view_component/SwitcherHeading';
import './topBase.css';
import { toJS } from 'mobx';
import InfiniteScrollBlock from '../view_component/InfiniteScrollBlock';
import UrlParamPageSelector from '../view_component/UrlParamPageSelector';
import { PropTypes } from 'prop-types';
import ScrollToTopButtom from '../view_component/ScrollToTopButton';

function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}
let pageSize=25;
@observer
class TopsAnime extends Component {

  constructor(props) {
    super(props);
    this.state = {advancedSearch:false, adultContent:false, finishedLoading:true,loadingMore:false};
    this.pageReOrder = this.pageReOrder.bind(this);
    this.fetchAnimeLocal = this.fetchAnimeLocal.bind(this);
    this.changeCriteria = this.changeCriteria.bind(this);
    this.changeGradePeriod = this.changeGradePeriod.bind(this);
    this.changeYear = this.changeYear.bind(this);
    this.changeSeason = this.changeSeason.bind(this);
    this.fetchMore = this.fetchMore.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    serverRegisterFetch(TopsAnime.getInitialValues);
    }
  componentDidMount()
  {
    TopsAnime.getInitialValues().then((d)=>{this.setState({finishedLoading:true}); return Promise.resolve(d)});
  }
  selectPage(){
    let filtersKeys = TopsAnime.getFilterKeys();
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let page = Number(parameters[filtersKeys.page]);
      scrollToAnchor('#item_'+((page*pageSize)+1),1000);
  }
  static async getInitialValues()
  {
    let filtersKeys = TopsAnime.getFilterKeys();
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let typeSubKey=parameters[filtersKeys.type];
    let period = parameters[filtersKeys.period] ||  parameters[filtersKeys.year] || parameters[filtersKeys.season];
    let resourceType = getCurrentPath().indexOf('/anime')!==-1 ? 'anime' : 'manga';

    console.log("LOOKRESS::",resourceType);
    let criteria = parameters[filtersKeys.criteria];
    if (!isSet(criteria) || criteria==='')
      MyAppPool.cLocation.setSingularParamVal('Grade',filtersKeys.criteria);
    if (!isSet(period) || period==='')
      MyAppPool.cLocation.setSingularParamVal('all',filtersKeys.period);
    if (resourceType==='manga')
      if (!isSet(typeSubKey) || typeSubKey==='')
        MyAppPool.cLocation.setSingularParamVal('Manga',filtersKeys.type);
    if (resourceType==='anime')
      if (!isSet(typeSubKey) || typeSubKey==='')
        MyAppPool.cLocation.setSingularParamVal('TV',filtersKeys.type)
    

    let p1 = TopsAnime.fetchAnime();
    await Promise.all([p1]);

    return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    TopsAnime.getInitialValues();
  }

  changeCriteria(){
    let filtersKeys = TopsAnime.getFilterKeys();
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.page);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.year);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.season);
    MyAppPool.cLocation.setSingularParamVal('all',filtersKeys.period);
    this.fetchAnimeLocal();
  }
  changeGradePeriod(){
    let filtersKeys = TopsAnime.getFilterKeys();
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.page);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.year);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.season);
    this.fetchAnimeLocal();
  }
  changeYear(){
    let filtersKeys = TopsAnime.getFilterKeys();
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.page);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.period);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.season);
    this.fetchAnimeLocal();
  }
  changeSeason(){
    let filtersKeys = TopsAnime.getFilterKeys();
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.page);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.period);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.year);
    this.fetchAnimeLocal();
  }
  clearFilters(){
    console.log("CLEARING FILTERS!");
    let filtersKeys = TopsAnime.getFilterKeys();
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.page);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.period);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.year);
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.season);
    MyAppPool.cLocation.setSingularParamVal('TV',filtersKeys.type);
    this.fetchAnimeLocal();
  }
  changeType = () =>{
    let filtersKeys = TopsAnime.getFilterKeys();
    MyAppPool.cLocation.setSingularParamVal('',filtersKeys.page);
    this.fetchAnimeLocal();
  }
  async fetchMore(maxPages){
    let filtersKeys = TopsAnime.getFilterKeys();
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    
    let page=Number(parameters[filtersKeys.page]);// || 0;
    console.log("LOOOK::",page,maxPages);
    if (page+1>=maxPages) return;
    MyAppPool.cLocation.setSingularParamVal(page+1,filtersKeys.page);

    this.setState({loadingMore:true});
    let p2 = TopsAnime.fetchAnime();
    await Promise.all([p2]);
    this.setState({loadingMore:false});
  }
  static fetchAnime()
  {
    let filterKeys =  TopsAnime.getFilterKeys();
    let resourceType = getCurrentPath().indexOf('/anime')!==-1 ? 'anime' : 'manga';
    let parametersBase = {};
    parametersBase[filterKeys.page]=0;
    parametersBase[filterKeys.criteria]='';
    parametersBase[filterKeys.year]='';
    parametersBase[filterKeys.season]='';
    parametersBase[filterKeys.type]='';
    parametersBase[filterKeys.period]='';

    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    let parameters = {...parametersBase,...parametersUrl};
    let subtype=parameters[filterKeys.type];
    let year = parameters[filterKeys.year];
    let season = parameters[filterKeys.season];
    let page = Number(parameters[filterKeys.page]);
    if (isSet(season) && season!=='')
      season=season.split('-');
    if (resourceType==='anime')
    {
      if (parameters[filterKeys.criteria]==='Grade')
      {
        if (isSet(parameters[filterKeys.season]) && parameters[filterKeys.season]!=='')
          return MyAppPool.cTops.getTopAnimeGradeSeasonPageByType(subtype,season[0],season[1],page,pageSize);
        if (isSet(parameters[filterKeys.year]) && parameters[filterKeys.year]!=='')
          return MyAppPool.cTops.getTopAnimeGradeYearPageByType(subtype,year,page,pageSize);
        if (parameters[filterKeys.period]==='airing')
          return MyAppPool.cTops.getTopAiringAnimePageByType(subtype,page,pageSize);
        return MyAppPool.cTops.getTopAnimeGradePageByType(subtype,page,pageSize);
      }
      if (parameters[filterKeys.criteria]=='Popularity')
      {
        if (parameters[filterKeys.period]==='upcoming')
          return MyAppPool.cTops.getTopUpcomingAnimePageByType(subtype,page,pageSize);

        return MyAppPool.cTops.getTopPopularAnimePageByPeriodAndType(parameters[filterKeys.period],subtype,page,pageSize);
      }
    }
    if (resourceType==='manga')
    {
      if (parameters[filterKeys.criteria]==='Grade')
      {
        if (isSet(parameters[filterKeys.season]) && parameters[filterKeys.season]!=='')
          return MyAppPool.cTops.getTopMangaGradeSeasonPageByType(subtype,season[0],season[1],page,pageSize);
        if (isSet(parameters[filterKeys.year]) && parameters[filterKeys.year]!=='')
          return MyAppPool.cTops.getTopMangaGradeYearPageByType(subtype,year,page,pageSize);
        if (parameters[filterKeys.period]==='airing')
          return MyAppPool.cTops.getTopAiringMangaPageByType(subtype,page,pageSize);
        return MyAppPool.cTops.getTopMangaGradePageByType(subtype,page,pageSize);
      }
      if (parameters[filterKeys.criteria]=='Popularity')
      {
        if (parameters[filterKeys.period]==='upcoming')
          return MyAppPool.cTops.getTopUpcomingMangaPageByType(subtype,page,pageSize);

        return MyAppPool.cTops.getTopPopularMangaPageByPeriodAndType(parameters[filterKeys.period],subtype,page,pageSize);
      }
    }

  }

  async fetchAnimeLocal()
  {
    this.setState({finishedLoading:false});
    let p2 = TopsAnime.fetchAnime();
    await Promise.all([p2]);
    this.setState({finishedLoading:true});
    return Promise.resolve();
  }
  
  pageReOrder()
  {

  }

  static getFilterKeys()
  {
    return {
      year:lng('ano'),
      season:lng('estacao'),
      type:lng('tipo'),
      page:lng('pagina'),
      criteria:lng('criterio'),
      period:lng('periodo'),
    };
  }

  render(){
    //console.log(MyAppPool.session.location.search);
    let resourceTypeFast = contentTypeToFastType(this.props.resourceType);//contentTypeToFastType('anime');
    let resourceType = this.props.resourceType;
    let filters = resourceType==='anime'?MyAppPool.cTops.getTopAnimeFilters():MyAppPool.cTops.getTopMangaFilters();
    let popularFilters = MyAppPool.cTops.getTopAnimePopularFilters();
    let periodoPopular=popularFilters.periodo;
    let filterTexts = filters.filterTexts;
    let {years,types,criteria,seasons,periodo} = filters;
    let {finishedLoading} = this.state;
    let filterKeys = TopsAnime.getFilterKeys();
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let typeSubKey=parameters[filterKeys.type];
    let emptySearchItems =Array.from(Array(20).keys());
    let fetching=false;
    let title = lng('Top Animes');
    if (resourceType==='manga')
      title = "Top Manga"+"/"+lng("Novel");


    let removerTagsTexts = {};
    removerTagsTexts[filterKeys.season]=filterTexts.seasonsTexts;
    removerTagsTexts[filterKeys.type]=filterTexts.typesTexts;
    removerTagsTexts[filterKeys.airing]=filterTexts.airingTexts;

    let currentPage=parameters[filterKeys.page];

    if (!isSet(currentPage)||currentPage==='')
      currentPage=0;

    let animes = null; 
    let criteriaParam = parameters[filterKeys.criteria];
    
    if (criteriaParam==='Grade')
    {
      animes=MyAppPool.cTops.getTopGradeAllPage(MyAppPool.session.tops,resourceType,typeSubKey,pageSize,currentPage);

      let year = parameters[filterKeys.year];
      let season = parameters[filterKeys.season];
      if (isSet(season) && season!=='')
        season=season.split('-');

        if (isSet(parameters[filterKeys.year]) && parameters[filterKeys.year]!=='')
        animes=  MyAppPool.cTops.getTopYearAllPage(MyAppPool.session.tops,resourceType,typeSubKey,year,pageSize,currentPage);
        if (isSet(parameters[filterKeys.season]) && parameters[filterKeys.season]!=='')
        animes=  MyAppPool.cTops.getTopSeasonAllPage(MyAppPool.session.tops,resourceType,typeSubKey,season[0],season[1],pageSize,currentPage);
          if (parameters[filterKeys.period]==='airing')
          animes=  MyAppPool.cTops.getAnimeTopAiringAllPage(MyAppPool.session.tops,resourceType,typeSubKey,pageSize,currentPage);
    }
    if (criteriaParam=='Popularity')
    {
      animes= MyAppPool.cTops.getTopPopularAllPage(MyAppPool.session.tops,resourceType,typeSubKey,parameters[filterKeys.period],pageSize,currentPage);

      if (parameters[filterKeys.period]==='upcoming')
        animes= MyAppPool.cTops.getTopPopularUpcomingAllPage(MyAppPool.session.tops,resourceType,typeSubKey,pageSize,currentPage);
    }

    let pageContent=[];
    let maxPages =0;
    let pagesNumber=0;
    let pages=[];
    if (isSet(animes) && isSet(animes.maxPages))
      maxPages = animes.maxPages;
    if (isSet(animes) && Array.isArray(animes.itens))
    {
      pageContent = animes.itens;
      pages = Array.from(Array(maxPages).keys());
      if (pages.length==0)
          pages[0]=0;

    }
    console.log("MAXPAGE:",maxPages);
    let fetchMore = this.fetchMore;
    if (currentPage>=maxPages)
      fetchMore = ()=>{return Promise.resolve();};

    console.log("PAGE:",mobToObj(pageContent))
    //console.log('psize:',pageSize,'bottom',currentPageRangeBottom,'top:',currentPageRangeTop,'length:',pageContent.length);
    
    let meta = {title:lng('Buscar Anime')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    //if (isMobile.any)
    //{
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div className="contentpage topsBase searchBase">
          <ContentBannerSetter modes={["bg"]} images={["https://files.nuecloud.cyou/buckets/mab/imgs/defaults/tops3.png"]}/>
          <div className='uk-flex uk-flex-row contentArea'> 
            <div className="leftContent">
                  
            </div>
            <div className="middleContent">
              
                <SwitcherHeading heading={title} 
                onClick={this.clearFilters}
                links={[
                  {to:getTopsRoute('anime'),text:lng('Animes')},
                  {to:getTopsRoute('manga'),text:lng('Mangas/Novels')}
                ]}/>
                <div className="" style={{padding:"10px"}}>
                <div style={{'width':'95%'}}>
                <div className="uk-flex uk-flex-row criterioRow">
                {lng('Criterio')}:
                <UrlParamHorizontalListButtons 
                        items={criteria}
                        filterKey={filterKeys.criteria}
                        onChangeFunction={this.changeCriteria}
                        textSubField={"text"}
                        valueSubField={"criteria"}
                />
                </div>
                {criteriaParam!=='Popularity'?
                <div>
                <div className="flexDrop criterioRow">
                {lng("Periodo")+": "}
                <UrlParamHorizontalListButtons 
                        items={periodo}
                        filterKey={filterKeys.period}
                        onChangeFunction={this.changeGradePeriod}
                        textSubField={"text"}
                        valueSubField={"criteria"}
                />
                <div style={{'marginRight':'10px'}}>{lng("ou")}</div>
                <UrlParamSelect 
                      selectDefaultName={lng('Ano')}
                      items={years}
                      filterKey={filterKeys.year}
                      onChangeFunction={this.changeYear}
                      />
                  {resourceType==='manga'?<></>:
                  [
                  <div style={{'marginLeft':'10px','marginRight':'10px'}}>{lng("ou")}</div>,
                  <UrlParamSelect 
                      selectDefaultName={lng('Temporada')}
                      items={seasons}
                      filterKey={filterKeys.season}
                      onChangeFunction={this.changeSeason}
                      textSubField="name"
                      valueSubField="id"
                      />
                  ]}

                      </div>  
                </div> :
                <div>
                <div className="uk-flex uk-flex-row criterioRow">
                {lng("Periodo")+": "}
                <UrlParamHorizontalListButtons 
                        items={periodoPopular}
                        filterKey={filterKeys.period}
                        onChangeFunction={this.fetchAnimeLocal}
                        textSubField={"text"}
                        valueSubField={"criteria"}
                />
                </div> 
                </div>}
                {lng('Filtro')+": "}
                      <UrlParamSelect 
                      items={types}
                      filterKey={filterKeys.type}
                      onChangeFunction={this.changeType}
                      textSubField="name"
                      valueSubField="id"
                      selectDefaultName={lng('Tipo')}
                />
                </div>
                <UrlParamRemoverTags 
                    onChangeFunction={this.fetchAnimeLocal}
                    paramToNiceText={removerTagsTexts}
                />
                </div>
                <div className="searchContentSection">
              {fetching?
                  <div className="searchContent">
                    {emptySearchItems.map((item, index) => {
                      return <div className="searchItemResult"><div className={" skeleton ratioDiv"}></div></div>
                    })
                  }
                  </div>
                  :
                    finishedLoading?
                    <InfiniteScrollBlock fetchFunction={()=>fetchMore(maxPages)}>
                        <div className="searchContent">
                          {pageContent.map((item, index) => {
                            let url= getObraRoute(item.id,item.title,resourceType,'#top');
                            let img = getImgAddress(item.mainimg,'/imgs/defaults/anime.webp','');
                            let grade = undefined;

                            if (isSet(item.averagegrade))
                              grade = {
                              minVal:5,
                              maxVal:10,
                              actualVal:item.averagegrade
                            };
                              
                              return <div id={"item_"+item.position} key={item.position} className="searchItemResult">
                                <SearchCard
                                  position={item.position}
                                  grade={grade}
                                  key={index}
                                  isHentai={false}
                                  title={item.title}
                                  url={url}
                                  resourceType={resourceTypeFast}
                                  resourceId={item.id}
                                  imgurl={img}
                                  date={item.airfrom}
                                  icon={getRelatedTypeIcon(item.type)}
                                  gradeable={true}
                                  iconText={lng(item.type)}
                                />
                              </div>
                            })
                          }
                          {this.state.loadingMore?
                          emptySearchItems.map((item, index) => {
                            return <div className="searchItemResult"><div className={" skeleton ratioDiv"}></div></div>
                          })
                          :<></>
                          }
                        </div>
                    </InfiniteScrollBlock>
                      :
                      <div className="searchContent">
                        {emptySearchItems.map((item, index) => {
                          return <div className="searchItemResult"><div className={" skeleton ratioDiv"}></div></div>
                        })
                      }
                      </div>
                  
            }
              </div>
          </div>
          <div className="rightContent">
              <ScrollToTopButtom />
              <div data-uk-sticky="offset: 190px">
              <UrlParamPageSelector 
                  items={pages}
                  filterKey={filterKeys.page}
                  onChangeFunction={this.selectPage}
              />
            </div>
          </div>
        </div>
        </div>
      </div>       
      )
    

}
}


TopsAnime.propTypes = {
  resourceType: PropTypes.string.isRequired
};
TopsAnime.defaultProps = {
  resourceType:'anime'
};


export default TopsAnime;
