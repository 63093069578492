import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { contentTypeToFastType } from '../util/typeu';
import MyAppPool from './../AppPool';
import "./GlobalMusicQueueMusicItem.css";
import ListWidget from './ListWidget';
import LinkV2 from './LinkV2';
import { getMusicRoute } from '../store/AppRoutes';

function themeGetType(type)
{
  if (type===0)
    return 'op';
  if (type===1)
    return 'ed';
  return '';
}
class GlobalMusicQueueMusicItem extends Component {

    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.removeFromPlaylist = this.removeFromPlaylist.bind(this);
        this.state = {hover:false};
    }

    removeFromPlaylist()
  {
    MyAppPool.cMusic.removeFromPlayQueue(this.props.music);
    MyAppPool.cPopMenu.deactivate();
  }

  play()
  {
      MyAppPool.cMusic.playMusicObject(this.props.music);
  }

  render(){
    let {music} = this.props;
    let resourceType = contentTypeToFastType('music');

    let showPlayButtons = false;
    if (music.ytid!=='')
        showPlayButtons=true;
    let extraInfo = themeGetType(music.type) + ' ' +music.where;
    if (extraInfo!==' ')
      extraInfo = '('+extraInfo+')'

    let showChangeOrderButton = this.props.showBars;
    //if (music.id===MyAppPool.cMusic.currentMusic.id)
    //{return <></>}


    return (
        <li className={"comp_queuemusicitem"}>
          <div onMouseLeave={()=>this.setState({hover:false})} onMouseOver={()=>this.setState({hover:true})} className="uk-flex uk-flex-row">
            {showChangeOrderButton?
                <div id="ytmusicchangeorderanchor"  className="fas fa-bars ytanchoricon"></div>
                :<></>
            }
            {
                music.id===MyAppPool.cMusic.currentMusic.id?
                <div  className="fas fa-play ytplayingicon"></div>
                :<></>
            }
            <div className="musicdata">
              <p className={"ytmusicname"}> <LinkV2 to={getMusicRoute(music.id,music.title+"-"+music.band,'#top')} className={"ytmusicname"}>{music.title + ' ' }</LinkV2></p>
              <p className={"ytsinger"}>{music.band}</p>
            </div>
            {showPlayButtons?
                <span onClick={this.play} data-uk-tooltip={lng('Tocar')} className="fas fa-play-circle ytplayicon"></span>
                :<></>
            }
            {showPlayButtons?
                <div id="ytmusicmenubutton" className="fas fa-ellipsis-v ytmenuicon" 
                onClick={(e)=>{
                  MyAppPool.cPopMenu.set(
                    <div>
                      <ul className="pop_ytmenulist">
                        <li className="pop_ytmenuitem" onClick={this.removeFromPlaylist}>
                          <span className={"fas pop_ytmenuitemicon fa-trash"} ></span><div className="ytpop_queuetextoption">{lng("Remover")}</div>
                        </li>
                    </ul>
                    <ListWidget resourceType={resourceType} resourceId={music.id}/>
                    </div>
                  ,'')}}
                >
                </div>
                
              :<></> 
            }
          </div>
         </li>
    

    );

  }
}

GlobalMusicQueueMusicItem.propTypes = {
  music: PropTypes.shape({
    title: PropTypes.string.isRequired,
    band: PropTypes.string.isRequired,
    where: PropTypes.string.isRequired,
    ytid: PropTypes.string.isRequired,
    id:PropTypes.number.isRequired}),
  showBars: PropTypes.bool.isRequired
};
GlobalMusicQueueMusicItem.defaultProps = {
  music:{
    title: '',
    band: '',
    where: '',
    ytid: '',
    id:0
  },
  showBars: false
};

export default GlobalMusicQueueMusicItem;
