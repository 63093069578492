import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../AppPool';
import { getObraRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { isMobile } from '../util/mobile';
import { convertDataToShowable, getImgAddress, getSiteShortName } from '../util/text';
import { isSet } from '../util/typeu';
import { serverRegisterFetch } from '../util/updateManager';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import { isServer } from './../util/windowUtil';
import TrailerBox from './../view_component/TrailerBox';
import './recentTrailers.css';
import { getRelatedTypeIcon } from '../util/icon';
import LinkV2 from '../view_component/LinkV2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TrailerYoutubePlayBig from './../view_component/TrailerYoutubePlayBig';
import ScrollToTopButtom from '../view_component/ScrollToTopButton';
import Spinner from '../view_component/Spinner';
import InfiniteScrollBlock from '../view_component/InfiniteScrollBlock';
   

function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}
function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

@observer
class RecentTrailerView extends Component {

  constructor(props) {
    super(props);
    this.state = {finishedLoading:false,loading: false };
    
    serverRegisterFetch(RecentTrailerView.getInitialValues);
  }

  componentDidMount()
  {
    RecentTrailerView.getInitialValues().then(()=>this.setState({finishedLoading:true}));
  }
  
  static async getInitialValues()
  {
    let p1 = MyAppPool.cLatestContent.getMoreRecentTrailers();
    return await Promise.all([p1]);
  }
  componentWillReceiveProps()
  {
    RecentTrailerView.getInitialValues();
  }

  render(){

    let trailers = MyAppPool.session.latestContent.trailers.items;
    
    let meta = {title:'Trailers | '+getSiteShortName()+'',desc:'',img:'/favicon.ico',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    let desc = lng('Bem vindo ao Nosso Anime Network! A plataforma de anime e mangá Brasileira mais moderna e mais atualizada, um database e comunidade. Faça parte da comunidade, Crie suas listas de animes ,mangás, empresas, personagens, pessoas, listas padrões e customizadas também! Leia resenhas, explore as comunidades, notícias e muito mais!');
    meta =  {
      title:'Trailers | '+getSiteShortName()+'',
      desc:desc,
      keywords:'',
      img:'/favicon.ico',
      imgwidth:'225',
      imgheight:'338',
      imgtype:'jpg'
    };
    return (
      <div className="home">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="title" content={meta.title} />
          <meta name="description" content={meta.desc} />
          <meta name="keywords" content={meta.keywords}/>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.desc} />
          <meta name="twitter:title" content={meta.title}/>
          <meta name="twitter:description" content={meta.desc}/>
          <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
          <meta property="og:image" content={getImgAddress(meta.img)} />
          <meta property="og:image:type" content={meta.imgtype}/>
          <meta property="og:image:width" content={meta.imgwidth}/>
          <meta property="og:image:height" content={meta.imgheight}/> 
        </Helmet>
        <div className="contentpage recent_trailers_view">
        <ContentBannerSetter modes={["bg"]} images={["https://files.nuecloud.cyou/buckets/mab/imgs/defaults/trailers3.png"]}/>

        
        <div className='uk-flex uk-flex-row contentArea'> 
            <div className="leftContent">  
            </div>

            <div className="middleContent" >
            <h1>{lng('Trailers Recentes')}</h1>
            <InfiniteScrollBlock fetchFunction={MyAppPool.cLatestContent.getMoreRecentTrailers} >
            <div className="flexDrop">
            {trailers.map((element, index) => {
                  let active='';
                  let icon = getRelatedTypeIcon(element.type);
                  let iconText = lng(element.type);
                  if (isMobile.any)
                  {
                    let obraRoute = getObraRoute(element.animeid,element.title,'anime','#top');
                  }

                return (
                <div className="videoItem" key={index} >
                    <div >
                    <TrailerYoutubePlayBig className="internal_video_item" height={"200px"} key={index} videourl={element.ytid} title={''}/>
                    </div>
                    <div className="uk-flex uk-flex-row">
                    <div className="videoItemLeft">
                    <LinkV2 aria-label={element.title}  onClick={(e)=>(isMobile.any)?e.preventDefault():null} to={getObraRoute(element.animeid,element.title,'anime','#top')}>
                      <LazyLoadImage alt={lng("Capa de")+" "+element.title} className='videoItemCapa'  src={getImgAddress(element.mainimg,'imgs/defaults/anime.webp','x25')}/>
                    </LinkV2>
                    
                    </div>
                    <div className="videoItemRight">
                    <LinkV2 aria-label={element.title} to={getObraRoute(element.animeid,element.title,'anime','#top')}>{element.title} {icon? <i className={icon + ' searchIcon'} data-uk-tooltip={iconText}></i> : <></>}</LinkV2>
                    {(isSet(element.airfrom) && element.airfrom!='')?
                        <div className="itemUnderText" onClick={()=>{}}>
                          {lng('Lançamento')+': '}
                          {convertDataToShowable(element.airfrom)}
                        </div>
                      :<></>}
                    </div>
                    </div>

                    
                </div>)})
                }
            </div>
                
            </InfiniteScrollBlock>
        
   
            </div>

            <div className="rightContent">
                <ScrollToTopButtom />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecentTrailerView;
