
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import "./MusicCardGroup.css";
import MusicCardHome from './MusicCardHome';
import { observer } from 'mobx-react';
import SkeletonBlock from './SkeletonBlock';



@observer
class MusicCardGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {currentPage:1,pageSize:props.pageSize};
    this.showMore = this.showMore.bind(this);
}
  showMore()
  {
    let {currentPage} = this.state;
    if (currentPage<(MyAppPool.session.latestContent.musics.loadedPages))
        this.setState({currentPage:currentPage+1});
    if (currentPage==(MyAppPool.session.latestContent.musics.loadedPages))
    {
        MyAppPool.cLatestContent.getMoreRecentMusic();
        this.setState({currentPage:currentPage+1});
    }
  }

  componentDidMount()
  {

  }

  render(){
    let {items,pageSize} = this.props;

    let {ready} = this.props;
    if (!ready)
      return <SkeletonBlock  style={{'width':'100%',height:'200px'}}/>

    if (!Array.isArray(items))
        return <></>;

    let {currentPage} = this.state;
    let maxItens = pageSize*currentPage;
    let filteredItens = items.slice(0,maxItens);
    let lastLoadedBatchAmount=MyAppPool.session.latestContent.musics.lastLoadedItensAmount;
    let loadedPages = MyAppPool.session.latestContent.musics.loadedPages;

    return <div className="comp_musiccardgroup">
        <div class="flexDrop" style={{"paddingBottom":"5px"}}>
        {filteredItens.map((item, index) => {
        return <div className="uk-card uk-card-default cardItem">
                          <MusicCardHome animeMusics={item}/>
               </div>
        })
        }
        </div>
        {lastLoadedBatchAmount===0 && (loadedPages===currentPage) || filteredItens.length===0 ?
        <></>:
        <button aria-label={lng('Mais') } onClick={this.showMore} className="uk-button uk-button-primary moreButton">{lng('Mais') }</button>
        }
    </div>;
}
}

MusicCardGroup.propTypes = {
    items:PropTypes.arrayOf(
        PropTypes.shape({
        animeid:PropTypes.number.isRequired,
        animetitle:PropTypes.string.isRequired,
        mainimg:PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
            title: PropTypes.string.isRequired,
            band: PropTypes.string.isRequired,
            where: PropTypes.string.isRequired,
            ytid: PropTypes.string.isRequired,
            id:PropTypes.number.isRequired}))
    })),
    showBars: PropTypes.bool.isRequired,
    pageSize:PropTypes.number,
    ready:PropTypes.number
  };


MusicCardGroup.defaultProps = {
    items:[{
            animeid:0,
            animetitle:'loading',
            mainimg:'',
            data:[
                {
                    title: 'loading',
                    band: '',
                    where: '',
                    ytid: '',
                    id:0
                  }
            ]
        }]
    ,
    showBars: false,
    pageSize:3,
    ready:0
  };
  
  export default MusicCardGroup;
