import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { getImgAddress } from '../util/text';
import MyAppPool from './../AppPool';
import ContentBanner from './../view_component/ContentBanner';
import './TopBar.css';
import { getBroadCastRoute, getSearchRoute, getSimulcastRoute, getTopsRoute, getTrailersRoute } from './../store/AppRoutes';
import UserTopBarBox from '../view_component/UserTopBarBox';
import DropDown from './../view_component/DropDown';
import SearchBox from '../view_component/SearchBox';
import { isMobile } from '../util/mobile';
import { getRgbRanges } from '../util/gradient';
import DonatorBar from '../view_component/DonatorBar';
import LinkV2 from '../view_component/LinkV2';
import { getLogoImage, getMobileLogo } from '../util/windowUtil';
import SignBar from '../view_component/SignBar';

@observer class TopBar extends Component {
    constructor(props){
        super(props);
        this.setDesignTheme = this.setDesignTheme.bind(this);
    }
    setDesignTheme(val){
        MyAppPool.session.theme=val;
        MyAppPool.cPreference.setTheme(val);
    }
  render(){
      let barHeight = 70;//uk-navbar-container
      // estrela <span className="uk-icon uk-margin-small-right" uk-icon="icon: star"></span>,
      let links=[
        {to:getSearchRoute('anime'),text:lng('Animes')},
        {to:getSearchRoute('manga'),text:lng('Mangas')},
        {to:getSearchRoute('music'),text:lng('Músicas')},
        {to:getSearchRoute('pessoa'),text:lng('Pessoas')},
        {to:getSearchRoute('personagem'),text:lng('Personagens')},
        {to:getSearchRoute('empresa'),text:lng('Empresas')},
        {to:getSearchRoute('revista'),text:lng('Revistas')},
        {to:getSearchRoute('usuario'),text:lng('Usuários')},
    ];
    let links1 = links.slice(0,links.length/2);
    let links2 = links.slice(links.length/2);

    let logoImage = getLogoImage();
    let logoMobileImage = getMobileLogo();

    let mobile = isMobile.any
      return(
        <div className="comp_topbar"> 
        <div className="topBar">
        <div className="navHolder">
        {mobile?
        <nav  className="uk-navbar topNav" style={{height:barHeight}}  id="navbar">
            <div id='top' className="uk-navbar-left subBar" style={{height:barHeight}}>
            <Link aria-label={"NANI!?"} to="/" className="uk-navbar-item uk-logo" href="#">
                <img src={logoMobileImage} style={{maxHeight:"54px"}} alt={lng("NANI logo")}/>
            </Link>
            </div> 
            <div className="uk-navbar-right subBar" style={{height:barHeight}}>
            <SearchBox />
                <div className=" ">
                    <UserTopBarBox />
                </div>
                <DropDown 
                btnContent = { [<i className="fas fa-bars"></i>]}
                >
                    <ul className="uk-nav uk-dropdown-nav uk-margin">
                    <li><LinkV2 to={getBroadCastRoute()}> {lng('Programação')} </LinkV2></li>
                    <li className="uk-nav-divider"></li>
                    <li><LinkV2 to={getSimulcastRoute()}> {'Simulcast'} </LinkV2></li>
                    <li className="uk-nav-divider"></li>
                    <li><LinkV2 to={getTrailersRoute()} >Trailers</LinkV2></li>
                    <li className="uk-nav-divider"></li>
                    <li><LinkV2 to={getTopsRoute('anime')}>{"Top Anime"}</LinkV2></li>
                    <li className="uk-nav-divider"></li>
                    <li><LinkV2 to={getTopsRoute('manga')}>{"Top Manga"+"/"+lng("Novel")}</LinkV2></li>
                    <li className="uk-nav-divider"></li>
                        {links.map((item, index) => {
                            return [<li key={index}><Link to={item.to}>{item.text}</Link></li>,
                            <li className="uk-nav-divider"></li>];
                        })}
                        
                    </ul>
                </DropDown>
            </div>
        </nav>
        :
        <nav  className="uk-navbar topNav" style={{height:barHeight}}  id="navbar">
        <div id='top' className="uk-navbar-left subBar" style={{height:barHeight}}>
            <Link aria-label={"NANI!?"} to="/" className="uk-navbar-item uk-logo" href="#">
                <img src={logoImage} style={{height:"54px"}} alt={lng("NANI logo")}/>
            </Link>
            <ul className="uk-navbar-nav uk-position-relative mainMenu" >
                <li>
                    
                <DropDown 
                btnContent = { [lng('Descobrir')]}
                >
                    <div className="uk-flex uk-flex-row dropLinksExplorar">
                        <div className="dropLinksExplorarContainer">
                        <ul className="uk-nav uk-dropdown-nav uk-margin">
                            {links1.map((item, index) => {
                                return [<li key={index}><Link to={item.to}>{item.text}</Link></li>,
                                <li className="uk-nav-divider"></li>];
                            })}
                        </ul>
                        </div>
                        <div className="dropLinksExplorarContainer">
                        <ul className="uk-nav uk-dropdown-nav uk-margin">
                            {links2.map((item, index) => {
                                return [<li key={index}><Link to={item.to}>{item.text}</Link></li>,
                                <li className="uk-nav-divider"></li>];
                            })}
                        </ul>
                        </div>
                    </div>
                </DropDown>
                </li>
            </ul>
            
            <DropDown 
                btnContent = { ["Tops"]}
                >
                <ul className="uk-nav uk-dropdown-nav uk-margin">
                <li key={0}><LinkV2 to={getTopsRoute('anime')}>{"Top Anime"}</LinkV2></li>
                <li className="uk-nav-divider"></li>
                <li key={1}><LinkV2 to={getTopsRoute('manga')}>{"Top Manga"+"/"+lng("Novel")}</LinkV2></li>
                <li className="uk-nav-divider"></li>
                </ul>
            </DropDown>
            <LinkV2 to={getTrailersRoute()}><div className="barBtn"> Trailers </div></LinkV2>
            <LinkV2 to={getBroadCastRoute()}><div className="barBtn"> {lng('Programação')} </div></LinkV2>
            <LinkV2 to={getSimulcastRoute()}><div className="barBtn"> {'Simulcast'} </div></LinkV2>

            </div>
            <div className="uk-navbar-right subBar" style={{height:barHeight}}>
                <div className=" nav-overlay uk-navbar-left uk-flex-1" >
                <SearchBox />
                
                </div>
                <div className="uk-navbar-item bellIcon clickable">
                    <i className="far fa-bell  "></i>
                </div>
                <div className=" ">
                    <UserTopBarBox />
                </div>
        </div>  
    </nav>}
        
        </div>
        </div>
        <ContentBanner url = {getImgAddress('imgs/defaults/bg1.webp')}/>
        <SignBar/>
    </div>
      ); //uk-navbar-right  uk-width-expand
      //imgs/banner/b1.webp
    }
}

export default TopBar;
