import React, { Component } from 'react';
import './HomeBanner.css';
import { lng } from '../ulanguages/language';
import MyAppPool from '../AppPool';
import { observer } from 'mobx-react';

//@observer
class HomeBanner extends Component {
  render() {
    //if (MyAppPool.cAuth.isGuest())
    //{
        return (
        <div className="banner">
            <div className="banner-background">
            <div className="rectangle blue-rect"></div>
            <div className="rectangle purple-rect"></div>
            <div className="rectangle orange-rect"></div>
            <div className="rectangle red-rect"></div>
            <div className="rectangle green-rect"></div>
            <div className="rectangle yellow-rect"></div>
            <div className="rectangle pink-rect"></div>
            <div className="rectangle teal-rect"></div>
            </div>
            <h1 className="banner-title"  id="hom_title">{lng("Your Local Anime&Manga Platform")}</h1>
            <div className="banner-content">
            <div className="banner-box" id="hom_box1">
                <h2>{lng("Create music playlists with your favorite openings&endings")}</h2>
            </div>
            <div className="banner-box"  id="hom_box2">
                <h2>{lng("Discover your obsessions, find out the best animes and mangas from each season and year")}</h2>
            </div>
            <div className="banner-box"  id="hom_box3">
                <h2>{lng("Expose your opinions. Leave reviews and impressions for others to see")}</h2>
            </div>
            <div className="banner-box"  id="hom_box4">
                <h2>{lng("Create custom lists about everything! Anime, Manga, Characters, Companies, Magazines, & People!")}</h2>
            </div>
            <div className="banner-box"  id="hom_box5">
                <h2>{lng("Crie listas de tudo!(inclusive músicas) Exponha suas opiniões. Descubra suas obssesões!")}</h2>
            </div>
            </div>
        </div>
        );
    //}
    return <>Potato</>
  }
}

export default HomeBanner;
