import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import "./Signbar.css";
import { getRgbRanges } from '../util/gradient';
import { toJS } from 'mobx';
import HoverDiv from './HoverDiv';
import renderHTML from 'react-render-html';
import { lng } from '../ulanguages/language';
import LinkV2 from './LinkV2';
import { getSupportPage } from '../store/AppRoutes';
 
@observer
class SignBar extends Component {
    constructor(props)
    {
        super(props);
        this.state = {};
    }
  componentDidMount()
  {
    
  }
  render()
  {
    if (MyAppPool.cAuth.isGuest())
    {
        return (
            <div className="comp_SignBar">
                <div className="centerInfo">
                    <button onClick={()=>{MyAppPool.cAuth.activateGlobalLoginBox();}} className="shiny-button-sign">{lng("Seja um Membro!")}</button>
                </div>
            </div>);
    }
    if (!MyAppPool.cAuth.isSupporter())
    {
    return (
        <div className="comp_SignBar">
            <div className="centerInfo">
                <a target="_blank" href={MyAppPool.session.campaign.url} className="shiny-button">{lng("Apoie")}</a>
                <LinkV2 className="knowMoreText" to={getSupportPage()}>{lng("Saiba Mais")}</LinkV2>
            </div>
        </div>);
    }else{
        return (
            <div className="comp_SignBar">
                <div className="centerInfo">
                    <button target="_blank" href={MyAppPool.session.campaign.url} className="shiny-button-gold">{lng("Agradecemos sua Parceria")}</button>
                </div>
            </div>);
    }
    return (<></>);
  }
}

SignBar.propTypes = {
    title: PropTypes.string.isRequired,
};
SignBar.defaultProps = {
    title:'',
};

export default SignBar;
