import React, { Component } from 'react';
import { getCurrentLocale, lng } from '../ulanguages/language';
import { capitalizeFirstLetterAllWords, getImgAddress, getSiteShortName } from '../util/text';
import MyAppPool from '../AppPool';
import { serverRegisterFetch } from '../util/updateManager';
import { observer } from 'mobx-react';
import { isMobile } from '../util/mobile';
import RelatedWorks from '../view_component/RelatedWorks';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import './simulcast.css'
import { Helmet } from 'react-helmet-async';

function getStartOfWeek(date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(date.setDate(diff));
  }

function getEndOfWeek(date) {
    const startOfWeek = new Date(date);
    return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
  }

@observer
class Simulcast extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
        daysOfWeek: this.getWeekDays(today),
        currentWeekStart: getStartOfWeek(today),
        currentWeekEnd: getEndOfWeek(today),
        todayWeekStart: getStartOfWeek(today),
        today: today,
        currentPickDay:today,
        currentAudioType:"Audio Original"
    };
    
    serverRegisterFetch (Simulcast.getInitialValues);
  }

  componentDidMount()
  {
    let today = new Date();
    Simulcast.getInitialValues();
  }
  static async getInitialValues()
  {
    let today = new Date();
    let firstDayOfWeek = getStartOfWeek(today);
    let lastDayOfWeek = getEndOfWeek(today);
    let p1 = MyAppPool.cAnime.getAnimeSimulcasting(firstDayOfWeek,lastDayOfWeek);
    await Promise.all([p1]);
    return Promise.resolve();
  }

  

  formatDate(date) {
    return date.toISOString().split('T')[0];
  }
  subtractDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }
  

  handleForward = () => {
    const { currentWeekStart, today,currentPickDay } = this.state;
    let newcurrentPickDay= this.subtractDays(currentPickDay,-7);
    if (newcurrentPickDay > today) return;
    MyAppPool.cAnime.getAnimeSimulcasting(getStartOfWeek(newcurrentPickDay),getEndOfWeek(newcurrentPickDay));
    this.setState({
        daysOfWeek: this.getWeekDays(newcurrentPickDay),
        currentWeekStart:  getStartOfWeek(newcurrentPickDay),
        currentWeekEnd: getEndOfWeek(newcurrentPickDay),
        currentPickDay:newcurrentPickDay
    });
  }

  handleBackward = () => {
    const { currentWeekStart,today,currentPickDay } = this.state;
    let newcurrentPickDay= this.subtractDays(currentPickDay,7);
    MyAppPool.cAnime.getAnimeSimulcasting(getStartOfWeek(newcurrentPickDay),getEndOfWeek(newcurrentPickDay));
    this.setState({
      daysOfWeek: this.getWeekDays(newcurrentPickDay),
      currentWeekStart:  getStartOfWeek(newcurrentPickDay),
      currentWeekEnd: getEndOfWeek(newcurrentPickDay),
      currentPickDay:newcurrentPickDay
    });
  }


  // Método para obter os dias da semana
  getWeekDays = (todayarg)=>{
    const today = new Date(todayarg);
    const sunday = today.getDate() - today.getDay();
    const daysOfWeek = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(today.setDate(sunday + i+1));
      daysOfWeek.push({
        date: currentDate.toLocaleDateString(getCurrentLocale(), {
          day: '2-digit',
          month: '2-digit',
          //year: 'numeric',
        }),
        dayName: capitalizeFirstLetterAllWords(currentDate.toLocaleDateString(getCurrentLocale(), { weekday: 'long' }))
      });
    }
    return daysOfWeek;
  }

  render() {
    const { currentWeekStart, currentWeekEnd,todayWeekStart,currentAudioType } = this.state;
    let meta = {title:'Simulcast | '+getSiteShortName()+'',desc:'',img:'/favicon.ico',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
   
    let desc = lng('O planejamento, data de lançamento e horário dos próximos episódios desta semana!');
    meta =  {
      title:'Simulcast  | '+getSiteShortName()+'',
      desc:desc,
      keywords:'',
      img:'/favicon.ico',
      imgwidth:'225',
      imgheight:'338',
      imgtype:'jpg'
    };

    let tvShowsByDay = MyAppPool.session.anime.tvShowsSimulcastByDay[currentAudioType];
    let dubs = Object.keys(MyAppPool.session.anime.tvShowsSimulcastByDay);
    dubs = [...new Set(dubs)];

    let sliderHeight=352;
    let sliderShowItens=7;
    let sliderImgSize='';
    if (isMobile.any)
    {
      sliderHeight=225;
      sliderShowItens=4;
      sliderImgSize='x50';
    }
    let hoje = new Date();
    let dia = hoje.getDay();
    let diaDate= hoje.toLocaleDateString(getCurrentLocale(), {
        day: '2-digit',
        month: '2-digit'
      });

    return (
      <div className="comp_viewsimulcast">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="title" content={meta.title} />
          <meta name="description" content={meta.desc} />
          <meta name="keywords" content={meta.keywords}/>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.desc} />
          <meta name="twitter:title" content={meta.title}/>
          <meta name="twitter:description" content={meta.desc}/>
          <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
          <meta property="og:image" content={getImgAddress(meta.img)} />
          <meta property="og:image:type" content={meta.imgtype}/>
          <meta property="og:image:width" content={meta.imgwidth}/>
          <meta property="og:image:height" content={meta.imgheight}/> 
        </Helmet>
        <ContentBannerSetter modes={["bg"]} images={["https://files.nuecloud.cyou/buckets/mab/imgs/defaults/simulcast.png"]}/>
        <h1>{"Simulcast "} 
        
        <select aria-label={lng("Selecionador de Tipo de Busca")} onChange={(event)=>this.setState({currentAudioType:event.target.value})} className="searchSelect clickable">
                {dubs.map((item, index) => {
                  let text = lng(item,true);
                  if (text==="-.-")
                    text = item;
                return <option value={item}>{text}</option>
                })} 
                </select>
          </h1>
        <div>
        <div className="uk-align-right">
          <span>{this.formatDate(currentWeekEnd)}</span><br/>
          {currentWeekEnd<hoje?
          <button className="uk-button uk-button-primary " onClick={this.handleForward}>{lng("Next Week")}</button>:<></>}
          </div>
          <div>
            <span>{this.formatDate(currentWeekStart)}</span> <br/>
            <button className="uk-button uk-button-primary" onClick={this.handleBackward}>{lng("Previous Week")}</button>
          </div>
          
        </div>
                {todayWeekStart.toString()==currentWeekStart.toString()?<div>
        <h2>{lng('Hoje')+` (${diaDate})`}</h2>
        <RelatedWorks 
            title=''
            notFoundText={lng('Carregando')+'...'}
            notFoundButtonText={''}
            showItemNumber={sliderShowItens}
            hideIfEmpty={1}
            imgSize={sliderImgSize}
            ready={1}
            height={sliderHeight}
            items={tvShowsByDay[dia]}/>
        </div>:<></>}

        {this.state.daysOfWeek.map((day, index) => {

          return <div key={index} className="day-category">
            <h2>{`${day.dayName} (${day.date})`}</h2>
            <RelatedWorks 
            title=''
            notFoundText={lng('Carregando')+'...'}
            notFoundButtonText={''}
            showItemNumber={sliderShowItens}
            hideIfEmpty={1}
            imgSize={sliderImgSize}
            ready={1}
            height={sliderHeight}
            items={tvShowsByDay[(index+1)%7]}/>
          </div>
         })}
        <br/>
      </div>
    );
  }
}

export default Simulcast;